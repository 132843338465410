import styled from "@emotion/styled"
import React from "react"
import { fonts, device, colors } from "../components/layout/GlobalStyles"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Seo } from "../components/Seo"
import linkedin from "../images/logos/linkedin-people-logo.svg"

const PageContainer = styled.article`
  padding: 54px 0px 72px;
  background: ${colors.secondary201};
  .desktop-image {
    display: none;
  }
  .mobile-image {
    display: block;
  }
  @media ${device.laptop} {
    padding: 188px 0px;
    .flex-container {
      display: flex;
      width: 1112.5px;
      justify-content: space-between;
    }
    .desktop-image {
      display: block;
      height: 330px;
      width: 448px;
    }
    .mobile-image {
      display: none;
    }
  }
  h1,
  h2 {
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
    padding: 0;
  }
`

const LeftColumn = styled.section`
  h1 {
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    font-size: 40px;
    margin: 0 0 0px 0px;
    padding: 0;
    padding: 0;
    font-weight: 300;
    letter-spacing: -1.88px;
    line-height: 52px;
  }
  /* pronoun */
  h1 + p {
    font-weight: 700;
    color: ${colors.dark3};
    font-family: ${fonts.secondary};
    margin: 0px 0px;
  }
  h2 {
    margin-bottom: 32px;
    font-family: ${fonts.primary};
    color: ${colors.dark3};
    font-size: 22px;
    font-weight: 900;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  .mobile-image {
    margin-bottom: 32px;
  }
  @media ${device.tablet} {
    h1 {
      line-height: 65px;
      font-size: 60px;
      margin-bottom: 5px;
    }
  }
  h1 + p {
    margin-bottom: 15px;
  }
`

const BodyText = styled.div`
  p {
    font-family: ${fonts.primary};
    font-size: 22px;
    color: ${colors.dark3};
    letter-spacing: -0.69px;
    line-height: 36px;
    margin-top: 20px;
  }
  @media ${device.laptop} {
    p {
      margin-top: 30px;
      width: 577px;
    }
  }
`

const RightColumn = styled.section`
  .desktop-image {
    margin-bottom: 70px;
  }
`

const Contact = styled.div`
  h3 {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: -1.25px;
    line-height: 48px;
    margin: 40px 0px 0px 0px;
  }
  a {
    color: ${colors.dark3};
    font-size: 22px;
    color: ${colors.dark3};
    letter-spacing: -0.69px;
    line-height: 36px;
    margin-top: 10px;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    font-size: 22px;
    color: ${colors.dark3};
    letter-spacing: -0.69px;
    line-height: 36px;
    margin-top: 10px;
  }
  @media ${device.tablet} {
    h3 {
      margin-top: 0px;
    }
    p {
      margin-top: 20px;
    }
  }
`

const PeopleTemplate = ({
  data: {
    allMarkdown: { frontmatter, body },
  },
}) => {
  return (
    <>
      <Seo
        title={`MLIFE Foundation - ${frontmatter.name} Bio`}
        description="We are an all-embracing, intergenerational, and intersectional faith-based congregation. Grassroots-led and community-driven."
      />
      <PageContainer>
        <div className="flex-container container">
          <LeftColumn>
            <h1>{frontmatter.name}</h1>
            <p>{frontmatter.pronoun}</p>
            <h2>{frontmatter.title}</h2>
            <h2 style={{ marginTop: "-30px" }}>{frontmatter.location}</h2>
            <GatsbyImage
              className="mobile-image"
              image={getImage(frontmatter.image)}
              alt={frontmatter.image.name}
            />
            <BodyText>
              <MDXRenderer>{body}</MDXRenderer>
            </BodyText>
          </LeftColumn>
          <RightColumn>
            <GatsbyImage
              className="desktop-image"
              image={getImage(frontmatter.image)}
              alt={frontmatter.image.name}
            />
            <Contact>
              <h3>Contact</h3>

              <p>
                {" "}
                <a
                  target="_blank"
                  className="email"
                  rel="noopener noreferrer"
                  href={`mailto:${frontmatter.contact}`}
                >
                  {frontmatter.contact}{" "}
                </a>
              </p>

              {frontmatter.site && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${frontmatter.site}`}
                >
                  <img
                    style={{ marginTop: "10px" }}
                    src={linkedin}
                    alt="linkedin logo"
                  />
                </a>
              )}
            </Contact>
          </RightColumn>
        </div>
      </PageContainer>
    </>
  )
}

export const query = graphql`
  query ($id: String!) {
    allMarkdown: mdx(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
        }
        name
        title
        slug
        category
        pronoun
        location
        site
        contact
        image {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: NONE, quality: 100)
          }
        }
      }
      body
    }
  }
`

export default PeopleTemplate
